.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.yes-it-is {
  color: green;
}

.no-it-is {
  color: crimson;
}

.indicator {
  margin: 0;
  padding: 0;
}